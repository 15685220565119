<template>
  <div class="station-details">
    <div class="content">
      <ContentBlock :required="true" :loading="isFetchingData">
        <div slot="instructions">
          <h5>{{ $t("Station Basics") }}</h5>
          <p>
            {{ $t('Remember once you create this station, you will have to install the launcher software on the Windows computer that this station is associated with. You can find instructions on how to achieve this') }}
            <a
              class="link-style"
              href="https://support.springboardvr.com/hc/en-us/articles/360003063053-Installing-SpringboardVR-s-3-0-Desktop-Client"
              target="_blank"
              rel="noopener"
            >{{ $t('here') }}</a>.
          </p>
        </div>
        <div class="station-basics">
          <UIInput :title="$t('Name')" required v-model="localStation.title"/>
          <UISelect :title="$t('Experience Type')" v-model="experience">
            <option
              v-for="experience in experiences"
              :key="experience.id"
              :value="experience.id"
            >{{ experience.title }}</option>
          </UISelect>
          <UISelect
            v-if="showVrSettings()"
            :title="$t('Idle Mode')"
            :instructions="$t('When the headset goes idle after 5 minutes of inactivity, the desktop will appear or a video reel of content you have on your station will be displayed in a loop.')"
            v-model="localStation.idleMode"
          >
            <option :value="null">{{ $t('Off') }}</option>
            <option :value="'videos'">{{ $t('Videos') }}</option>
            <option :value="'desktop'">{{ $t('Desktop') }}</option>
          </UISelect>
          <div v-if="showVrSettings()">
            <UIFormItem
              v-if="station.idleMode === 'videos'"
              :title="$t('Default Idle Videos Volume')"
              :instructions="$t('The volume will default back to this whenever Idle Mode starts playing videos.')"
            >
              <VolumeSlider class="volumeslider" v-model="localStation.idleModeVolume"/>
            </UIFormItem>
          </div>
        </div>
      </ContentBlock>

      <ContentBlock :loading="isFetchingData">
        <div slot="instructions">
          <h5>{{ $t("Additional Options") }}</h5>
        </div>
        <div class="additional-options">
          <UISwitch
            class="switch"
            :title="$t('Reservations Enabled')"
            :instructions="$t(`Enable online reservations for this station.`)"
            v-model="localStation.bookingEnabled"
          />
          <UISwitch
            v-if="showVrSettings()"
            class="switch"
            :title="$t('Default Dashboard Elements')"
            :instructions="$t(`When you hit the system button, you can see the SteamVR overlay.`)"
            v-model="localStation.defaultDashboardElementsEnabled"
          />
          <UISwitch
            v-if="showVrSettings()"
            class="switch"
            :title="$t('Theme Park Mode')"
            :instructions="$t(`Enables keyboard shortcuts that can be bound to USB connected buttons to start (Ctrl-Shift-F1) and stop (Ctrl-Shift-F2) a title. When Launcher is Disabled, will play a trailer reel based on the videos uploaded for the content in your library rather than open the launcher.`)"
            v-model="localStation.themeParkModeEnabled"
          />
          <UISwitch
            v-if="showVrSettings()"
            class="switch"
            :title="$t('Timer Enabled')"
            :instructions="$t(`If timer is disabled, content will always be ready to launch.`)"
            v-model="localStation.timerEnabled"
          />
          <UISwitch
            v-if="showVrSettings()"
            class="switch"
            :title="$t('Launcher Content Preview Enabled')"
            :instructions="$t(`When enabled, content will appear but users will not be able to launch content until time is added. If disabled, no content will appear in the launcher.`)"
            v-model="localStation.launcherContentPreviewEnabled"
          />
          <UISwitch
            v-if="showVrSettings()"
            class="switch"
            :title="$t('Launcher Enabled')"
            :instructions="$t(`If disabled, content must be remotely launched from Station Monitor.`)"
            v-model="localStation.launcherEnabled"
          />
          <UISwitch
            v-if="showVrSettings()"
            class="switch"
            :title="$t('Quit Content When Play Time Is Up')"
            :instructions="$t(`Rather than bringing up the overlay, the launcher will quit the title that is currently running and will return to the launcher environment.`)"
            v-model="localStation.quitGameOnTimeUp"
          />
        </div>
      </ContentBlock>
      <ContentBlock v-if="localStation && localStation.hardware && localStation.hardware.length">
        <div slot="instructions">
          <h5>{{ $t("Hardware/Software Info") }}</h5>
        </div>
        <div class>
          <ul class="hardware-info">
            <li
              class="hardwareitem"
              v-for="({name, decoratedValue}) in station.hardware"
              :key="name"
            >
              <h5 class="f-tiny">{{ name }}</h5>
              <p class="f-body">{{ decoratedValue }}</p>
            </li>
          </ul>
        </div>
      </ContentBlock>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import ContentBlock from '@/core/shared/components/ContentBlock';
import VolumeSlider from '../shared/VolumeSlider';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UISwitch from '@/core/shared/components/ui/UISwitch';

@Component({
  components: {
    UIInput,
    UISelect,
    UISwitch,
    UIFormItem,
    ContentBlock,
    VolumeSlider,
  },
  props: {
    station: {
      type: Object,
      required: true,
    },
    experiences: {
      type: Array,
      required: true,
    },
  },
})
export default class StationDetails extends Vue {
  get localStation () {
    return new Proxy(this.station, {
      set: (target, property, value) => {
        this.$emit('edit', property, value);
        return true;
      },
    });
  }

  get isFetchingData () {
    return this.$store.state.stations.isFetchingStationDetail && !this.localStation.idleModeVolume;
  }

  get experience () {
    if (this.localStation.experience) return this.localStation.experience.id;
  }

  set experience (experienceId) {
    this.localStation.experience = { id: experienceId };
  }

  created () {
    if (this.localStation.idleModeVolume === null) this.$set(this.localStation, 'idleModeVolume', 0.5);
  }

  showVrSettings () {
    const station = this.experiences.find(experience => experience.id === this.localStation.experience.id);
    return station?.type === 'vr';
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.station-details {
  & .station-basics {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-gap: var(--spacingMd);
      grid-template-columns: repeat(2, 1fr);
    }
  }
  & .volumeslider {
    padding: 1.5rem 0;
  }

  & .additional-options {
    display: grid;
    grid-gap: var(--spacingMd);
    @media (--tablet) {
      grid-template-columns: repeat(6, 1fr);
    }
    & .switch {
      grid-column: 1 / -1;
      @media (--tablet) {
        grid-column: span 3;
      }
      @media (--desktop) {
        grid-column: span 3;
      }
    }
  }
  & .folderpath {
    @media (--tablet) {
      grid-column: 1 / -1;
    }
  }
  & .hardware-info {
    display: grid;
    grid-gap: var(--spacingLg);
    list-style: none;
    padding: 0;
    margin: 0;
    @media (--tablet) {
      grid-template-columns: repeat(12, 1fr);
    }
    & .hardwareitem {
      & h5 {
        margin-top: 0;
        margin-bottom: var(--spacingXS);
      }
      & p {
        margin: 0;
        word-wrap: break-word;
      }

      @media (--tablet) {
        grid-column: span 4;
      }
      @media (--desktop) {
        grid-column: span 3;
      }
    }
  }
  & .measure {
    max-width: 26em;
  }
  & .measure-wide {
    max-width: 38em;
  }
}
</style>
